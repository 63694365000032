import {Component, OnInit} from '@angular/core';
import {Bornes, contries, enterprise} from 'src/app/models/user';
import {UserService} from 'src/app/services/users/user.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {CognitoService} from 'src/app/services/cognito.service';
import {DataSharingService} from 'src/app/services/DataSharingService.service';
import {GeocodeService} from "../../services/geocode.service";
import {Responds} from "../../models/geocode/responds";
import {status} from "../../models/geocode/enums/status";


@Component({
  selector: 'app-pages-add-borns',
  templateUrl: './pages-add-borns.component.html',
  styleUrls: ['./pages-add-borns.component.css']
})
export class PagesAddBornsComponent implements OnInit {
  isAlert: boolean = false;
  isAlertSuccess: boolean = false;
  alertMSG: string = '';
  bornes!: Bornes;

  addBornForm : FormGroup = new FormGroup({
    evseId: new FormControl('', []),
    name: new FormControl('', []),
    price: new FormControl(0, []),
    address: new FormControl('', []),
    postalCode: new FormControl('', []),
    city: new FormControl('', []),
    country: new FormControl('', []),
  });

  enterpriseOriginal : enterprise = {
    "id": 0,
    "username": "",
    "userPoolId": "eu-west-3_7RRotegEb",
    "email": "",
    "commercialName": "",
    "siren": "",
    "siret": "",
    "intercomVAT": "",
    "phoneNumber": "",
    "city": "",
    "streetNumber": 0,
    "street": "",
    "complement": "",
    "zipCode": "",
    "region": "",
    "country": contries.France,
    "employees" :[]
  }

  enterprise: enterprise = {...this.enterpriseOriginal};

  constructor( private userService: UserService, private congitoService: CognitoService, private dataSharingService: DataSharingService, private router: Router, private geocodeService:GeocodeService) { }


  newBorn(){
    this.bornes = {
      ownerId: this.enterprise.id,
      ...this.addBornForm.value,

    };

    this.geocodeService.translateAddressToLatitudeAndLongitude(this.bornes.address, this.bornes.city, this.bornes.postalCode).subscribe({
      next: value => {
        if (value.error_message){
          this.alertMSGf(value.error_message);
          this.isAlert = true
        }

        if (value.status === status.ok) {
          this.bornes.latitude = value.results[0].geometry.location.lat.toString()
          this.bornes.longitude = value.results[0].geometry.location.lng.toString()

          /* old code */
          console.log("click new")
          this.userService.createNewEnterpriseChargingPoint(this.enterprise.id, this.bornes)
            .subscribe((data: Bornes) => {
                console.log('data update =>', data)
                this.alertMSGf('Born ajouté avec succès.');
                this.isAlertSuccess = true;
                setTimeout(() => {
                  this.router.navigate(['/pages-allBornes']);
                }, 3000);
              },
              (error) => {
                console.log('error =>', error);
                const errorMessage = "Une erreur s'est produite. Veuillez réessayer.";
                this.alertMSGf(errorMessage);
                this.isAlertSuccess = false;
              })
        }

        if (value.status === status.invalid_request) {
          this.alertMSGf("Demande non valide");
          this.isAlert = true
        }

        if (value.status === status.zero_results) {
          this.alertMSGf("Nous n'avons pas trouvé votre adresse");
          this.isAlert = true
        }

      },
      error: error => {
        this.alertMSGf(error.message);
        this.isAlert = true
      }
    })
  }

  ngOnInit(): void {
    this.dataSharingService.currentEnterpriseData.subscribe(data=> {
      if(data && data.username !== "") {
        this.enterprise = data;
      } else {
        this.congitoService.getCurrentUser().then(result => {
          result.subscribe( enterprise => {
            this.enterprise = enterprise;
          })
        });
      }

    })

  }


  private alertMSGf(msg: string) {
    this.alertMSG = msg;
    this.isAlert = true;

  }
  closeAlert() {
    this.alertMSG = '';
    this.isAlert = false;
  }

}
