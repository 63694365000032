<main id="main" class="main">
  <app-log-out-component></app-log-out-component>
  <section class="section">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Configurations des bornes</h3>
            <a
              class="button-Back d-flex justify-content-start"
              routerLink="/pages-allBornes"
            >
              <i id="iconBack" class="ri-arrow-drop-left-line"></i>
              <p class="btn-link button-Back">listes des bornes</p>
            </a>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <p class="col-3" id="wsLinkToBeCopied">
              {{ this.wsLink }}
            </p>

            <button
              class="col-2 d-flex justify-content-center align-items-center btn btn-primary"
              (click)="LinkCopier()"
              *ngIf="
                this.wsLink !==
                'Nous sommes desolé mais nous n\'avons pas retrouvé le lien pour la borne indiqué.'
              "
            >
              Copier Lien
            </button>
          </div>
          <div *ngIf="conf; else spinner" class="card-body">
            {{ this.conf }}
          </div>
          <ng-template #spinner class="text-center">
            <img
              src="assets/img/spinner.gif"
              alt="chargement en cours."
              class="rounded mx-auto d-block"
            />
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</main>
