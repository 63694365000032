import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Responds} from "../models/geocode/responds"


const API_URL = environment.GOOGLE_GEOCODE_API;
const API_KEY = environment.GOOGLE_GEOCODE_KEY;
const httpOptions = {
  headers: new HttpHeaders({'Content-Type':  'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class GeocodeService {

  constructor(private http: HttpClient) { }

  translateAddressToLatitudeAndLongitude(address :string, city:string, postalCode:string){

    console.log(API_URL,API_KEY)
    const url = `${API_URL}address=${address}+${city}+${postalCode}&key=${API_KEY}`
    return this.http.post<Responds>(url , null);
  }
}
