import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BorneResponseType } from '../../models/borneResponseType';
import { UserService } from '../../services/users/user.service';

@Component({
  selector: 'app-page-conf-borne',
  templateUrl: './page-conf-borne.component.html',
  styleUrls: ['./page-conf-borne.component.css'],
})
export class PageConfBorneComponent implements OnInit {
  constructor(private router: Router, private userService: UserService) {}

  isAborne: boolean = false;
  borneId: number = Number(localStorage.getItem('bor') || 0);
  entId: number = Number(localStorage.getItem('ent') || 0);
  confs: BorneResponseType = {
    configuration_key: [],
    unknown: [],
  };
  conf: string = '';
  wsLink!: string;
  ngOnInit(): void {
    console.log(this.borneId, this.entId);
    this.getAllConfigurations(this.borneId, this.entId);

    if (this.borneId != 0) {
      this.wsLink = environment.getSocket + `${this.borneId}`;
    } else {
      this.wsLink =
        "Nous sommes desolé mais nous n'avons pas retrouvé le lien pour la borne indiqué.";
    }
  }

  LinkCopier() {
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.wsLink);

    // Alert the copied text
    alert('Copied the text: ' + this.wsLink);
  }

  getAllConfigurations(borneId: number, entId: number) {
    if (borneId !== 0 && entId !== 0) {
      this.userService.getBorneConf(entId, borneId).subscribe({
        next: (resp) => {
          this.conf = resp;
          //this.confs = this.parsePlainTextToJson(resp)
          console.log(resp);
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  leavePage() {
    localStorage.removeItem('borne');
    localStorage.removeItem('ent');
  }

  /* private parsePlainTextToJson(text: string): BorneResponseType {
    // Extract the relevant part of the text
    const regex = /configuration_key=$$(.*?)$$/;
    const match = text.match(regex);

    if (match && match[1]) {
      const configString = match[1].trim();
      // Convert the string to a valid JSON format
      const jsonString = configString
        .replace(/'/g, '"') // Replace single quotes with double quotes
        .replace(/(\w+):/g, '"$1":') // Add quotes around keys
        .replace(/(\w+)(?=,|\})/g, '"$1"'); // Add quotes around remaining keys

      return JSON.parse(`{"configuration_key":[${jsonString}]}`);
    }

      throw new Error('Invalid response format');
  }*/

  /*parsePlainTextResponse(responseText: string): BorneResponseType {
    try {
      // Split the response into lines
      const lines = responseText.split('\n');

      // Initialize the result object
      const result: BorneResponseType = {
        configuration_key: [],
        unknown: []
      };

      // Parse each line
      let currentKey = '';
      let currentValue = '';

      for (const line of lines) {
        if (line.startsWith('[')) {
          // Start of a new item
          if (currentValue.trim()) {
            result.configuration_key.push({
              key: currentKey,
              value: currentValue.trim(),
              readonly: false
            });
          }
          currentKey = '';
          currentValue = '';
        } else if (line.includes(':')) {
          // Extract key and value
          const [key, value] = line.split(':', 2);
          currentKey = key.trim();
          currentValue += value.trim() + '\n';
        }
      }

      // Handle the last item if present
      if (currentValue.trim()) {
        result.configuration_key.push({
          key: currentKey,
          value: currentValue.trim(),
          readonly: false
        });
      }

      return result;
    } catch (error) {
      console.error('Error parsing plain text response:', error);
      throw new Error('Invalid plain text response');
    }
  }*/
}
